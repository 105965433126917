import { useState } from "react";

import { Grid, GridState, GridLocation, initialGridState } from "./Grid";
import { ResourceTray, Resource } from "./ResourceTray";

export type GameState = {
  gridState: GridState;
  selectedResource: Resource | null;
  deleteMode: boolean;
};

function App() {
  const [gameState, setGameState] = useState<GameState>({
    gridState: initialGridState(),
    selectedResource: null,
    deleteMode: false,
  });

  function selectResource(selectedResource: Resource) {
    if (selectedResource === gameState.selectedResource) {
      setGameState({ ...gameState, selectedResource: null, deleteMode: false });
    } else {
      setGameState({ ...gameState, selectedResource, deleteMode: false });
    }
  }

  function placeResource(placedResource: Resource, location: GridLocation) {
    const gridState = [...gameState.gridState];
    gridState[location.x][location.y] = { contents: placedResource };
    setGameState({
      ...gameState,
      gridState,
      selectedResource: null,
    });
  }

  function removeResource(location: GridLocation) {
    const gridState = [...gameState.gridState];
    gridState[location.x][location.y] = {};
    setGameState({
      ...gameState,
      gridState,
      deleteMode: false,
    });
  }

  return (
    <>
      <Grid
        state={gameState.gridState}
        onGridClick={(location) => {
          if (gameState.selectedResource) {
            placeResource(gameState.selectedResource, location);
          } else if (gameState.deleteMode) {
            removeResource(location);
          }
        }}
      />
      <ResourceTray
        selectedResource={gameState.selectedResource}
        deleteMode={gameState.deleteMode}
        onSelectResource={(resource) => {
          selectResource(resource);
        }}
        onEnterDelete={() => {
          setGameState({
            ...gameState,
            selectedResource: null,
            deleteMode: !gameState.deleteMode,
          });
        }}
      />
    </>
  );
}

export default App;
