import { Resource } from "./ResourceTray";

export type GridEntity = {
  contents?: Resource; // TODO include more than just Resources
};

export type GridState = GridEntity[][];

export type GridLocation = {
  x: number;
  y: number;
};

export function Grid(props: {
  state: GridState;
  onGridClick: (location: GridLocation) => void;
}) {
  return (
    <table>
      <tbody>
        {props.state.map((rows, rowIndex) => (
          <tr>
            {rows.map((col, colIndex) => (
              <td
                onClick={() => props.onGridClick({ x: rowIndex, y: colIndex })}
              >
                {col.contents ?? " "}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function initialGridState(): GridState {
  const ROWS = 10;
  const COLS = 10;

  const grid = [];

  for (let i = 0; i < ROWS; i++) {
    const row = [];
    for (let j = 0; j < COLS; j++) {
      row.push({});
    }
    grid.push(row);
  }

  return grid;
}
