export type Resource = "X" | "O";

export function ResourceTray(props: {
  selectedResource: Resource | null;
  deleteMode: boolean;
  onSelectResource: (selectedResource: Resource) => void;
  onEnterDelete: () => void;
}) {
  const availableResources: Resource[] = ["X", "O"];

  return (
    <div className={"resource-tray"}>
      <h1>Resources:</h1>
      {availableResources.map((resource) => (
        <button
          onClick={() => props.onSelectResource(resource)}
          style={{
            backgroundColor:
              props.selectedResource === resource ? "grey" : "blue",
          }}
        >
          {resource}
        </button>
      ))}

      <button
        onClick={() => {
          props.onEnterDelete();
        }}
        style={{ backgroundColor: props.deleteMode ? "grey" : "red" }}
      >
        🗑
      </button>

      <div>
        <span>
          Selected Resource:{" "}
          {props.deleteMode ? "Deleting!" : props.selectedResource ?? "None"}
        </span>
      </div>
    </div>
  );
}
